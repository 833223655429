<template>
  <div class="container">
    <van-nav-bar 
      title="" 
      fixed 
      @click-left="onClickLeft" 
      left-arrow />
    <div class="lunbo">
      <div class="jiangRecordsList">
        <div class="jiangRecordsList-item" v-for="(x, i) in list" :key="i">{{ x }}</div>
      </div>
    </div>
    
    <div class="jiangRecords" @click="enterRecords">
      <div class="jiangRecords-item">
        <span>中奖</span>
        <span>记录</span>
      </div>
    </div>

    <div class="beijing">
      <img class="beijing-img" src="./img/img.png" alt="">

      <div class="beijing-dazhuanpan">
        <img class="beijing-dazhuanpan-shou" src="./img/shou.png" alt="" v-show="shou_yao">
        <img class="beijing-dazhuanpan-yao" src="./img/yao.gif" alt="" v-show="!shou_yao">
        <img class="beijing-dazhuanpan-yun" src="./img/yun.png" alt="">
        <div class="beijing-dazhuanpan-btn">
          <div class="beijing-dazhuanpan-btn-item">
            <div class="beijing-dazhuanpan-btn-item-content">{{ totalActivity }}</div>
            <img class="beijing-dazhuanpan-btn-item-img" src="./img/btnl.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="activeRule">
      <p class="activeRule-text">
        <span class="activeRule-text-zi">活动规则</span>
      </p>
        <ul class="activeRule-ul" v-if="activityRule">
        <li class="activeRule-ul-li" v-for="(item,index) in activityRule" :key="index">{{ item }}</li>
      </ul>
      <ul v-else class="activeRule-ul">
        <li class="activeRule-ul-li">暂无数据</li>
      </ul>
    </div>
    
    <div class="userListBox">
      <div class="userList" v-for="x in tiskList" :key="x.id">
        <div class="userList-btn">
          <span class="userList-btn-name">{{ x.name }}</span>
            <van-button round size="mini" color="linear-gradient(to right, #FF4982, #FF3F3F)" @click="goGuang(x.activeType)">
              <div style="transform: scale(.9); margin: 0 3px;">去赚取</div>
            </van-button>
        </div>

        <div class="userList-item" v-for="(y, i) in x.list" :key="i">
          <div class="userList-item-yuan"></div>
          <div class="userList-item-content">
            <span>{{ y.activeTypeName }}</span>
            <span style="color: #E54320;">+{{ y.active }}</span>
            <span>{{ y.activeTyDanwei }}</span>
          </div>
        </div>
      </div>

      <p v-if="Apple" style="font-size: 14px; color: #E54320; text-align: center;">该活动及奖励与Apple Inc.无关</p>
    </div>

    <vant-dialong
      :show="show"
      :yao_res="yao_res"
      :active="active"
      @closeShow="closeShow"
      @tryAgain="tryAgain"
      @qvzhuanqv="qvzhuanqv"
      @openShareFriend="openShareFriend" />

    <none-jiang
      :NoneJiang="NoneJiang"
      :active="active"
      @closeNoneJiang="closeNoneJiang"
      @tryAgain="tryAgain"
      @qvzhuanqv="qvzhuanqv" />

    <coin-chance
      :CoinChance="CoinChance"
      :zhifuType="zhifuType"
      :active="active"
      @tryAgain="tryAgain"
      @closeCoinChance="closeCoinChance"
      @duihuanChance="duihuanChance"
      @qvzhuanqv="qvzhuanqv" />

    <share-friend
      ref="ShareFriend"
      :ShareFriend="ShareFriend"
      :endInfo="endInfo"
      :src="src"
      @closeShareFriend="closeShareFriend" />

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>

import VantDialong from './components/VantDialong'
import NoneJiang from './components/NoneJiang'
import CoinChance from './components/CoinChance'
import Loading from './components/Loading'
import ShareFriend from './components/ShareFriend'
import { getTiskList, getsuipianPage, getYaoyiyaoPage, getErCode } from '@/api/Ge/port'
import { Toast } from 'vant'

export default {
  components: { VantDialong, NoneJiang, CoinChance, Loading, ShareFriend },

  data () {
    return {
      show: false,
      NoneJiang: false,
      CoinChance: false,
      ShareFriend: false,
      tiskList: [],
      totalActivity: 0,
      formInline: {
        activityId: '',
        payType: '1'
      },

      result: 0,
      duihuanBoolean: false, // true 兑换机会的摇一摇
      shou_yao: true, // true 静态   false 动态
      src: '',

      loading: false,
      yao_res: {},
      activation: 0,
      activityRule: [],
      zhifuType: [],
      endInfo: '1',
      exchange: '',
      noneActive: false,
      list: [],
      active: 0,
      threeRecords: false, // 3秒内提交1次
      Apple: true
    }
  },
  
  created () {
    this.$store.commit('setSession', this.$route.query.session)
    this.getYaoyiyaoPage()
    this.getTiskList()
    this.getSuipianliList()
  },

  mounted () {
    this.isApple()
    this.getErCode()
    window.startSharkResult = this.startSharkResult
    window.rewardVideoSuccess  = this.rewardVideoSuccess
    setTimeout(() => {
      this.startSharking()
    }, 500)
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
  },

  methods: {
    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
      this.endShark()
    },

    showRewardVideo () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.showRewardVideo.postMessage("2")
      }
      if (isAndroid) {
        window.android.showRewardVideo('2')
      }
    },

    activityTaskVideo () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.activityTaskVideo.postMessage("yao")
      }
      if (isAndroid) {
        window.android.activityTaskVideo()
      }
    },

    activityTaskMall () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.activityTaskMall.postMessage("yao")
      }
      if (isAndroid) {
        window.android.activityTaskMall()
      }
    },

    startSharking () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.startShark.postMessage("yao")
      }
      if (isAndroid) {
        window.android.startShark()
      }
    },

    endShark () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.endShark.postMessage("yao")
      }
      if (isAndroid) {
        window.android.endShark()
      }
    },

    isApple () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        this.Apple = true
      }
      if (isAndroid) {
        this.Apple = false
      }
    },

    rewardVideoSuccess () {
      this.getPage2()
    },

    enterRecords () {
      this.endShark()
      this.$router.push({
        path: '/shakeIfOffRecords'
      })
    },

    closeShow () {
      this.show = false
    },

    closeNoneJiang () {
      this.NoneJiang = false
    },

    closeCoinChance () {
      this.CoinChance = false
    },

    closeShareFriend () {
      this.ShareFriend = false
      setTimeout(() => {
        this.startSharking()
      }, 100)
    },

    openShareFriend () {
      this.loading = true
      setTimeout(() =>{
        this.loading = false
        this.ShareFriend = true
        this.$nextTick(() => {
          this.$refs.ShareFriend.toImage()
        })
      }, 1000)
    },

    tryAgain () {
      setTimeout(() => {
        this.startSharking()
      }, 100)
    },

    closeLoading () {
      this.closeLoading = false
    },

    qvzhuanqv () {
      this.showRewardVideo()
    },

    duihuanChance (type) {
      Toast('摇一摇机会+1')
      this.duihuanBoolean = true
      this.formInline.payType = type
      setTimeout(() => {
        this.startSharking()
      }, 100)
    },

    getPage2 () {
      getYaoyiyaoPage().then(res => {
        if (res.data.code === 0) {
          this.totalActivity = Number(res.data.data.totalActivity)
        }
      })
    },

    reset () {
      // 复位
      this.result = 0
      this.shou_yao = true
    },

    startSharkResult () {
      if (this.threeRecords == false){
        let that = this
        that.threeRecords = true
        setTimeout(()=>{
          that.threeRecords = false
          if (this.duihuanBoolean) {
            // 兑换机会调的
            this.getsuipianPage2()
            this.duihuanBoolean = false
          } else {
            this.startShark()
          }
          this.reset()
        }, 2000)
      }
    },

    startShark () {
      if (this.noneActive) { // 是否有活动
        Toast('当前没有正在进行的活动')
        this.startSharking()
      } else {
        if (this.exchange == '1') { // 是否免费  1收费
          if (this.totalActivity < this.activation) {
            this.CoinChance = true
            this.endShark()
          } else {
            this.getsuipianPage()
          }
        } else {
          this.getsuipianPage()
        }
      }
    },

    getsuipianPage () {
      this.loading = true
      let formInline = { activityId: this.formInline.activityId, payType: '1' }
      getsuipianPage(formInline).then(res => {
        if (res.data.code === 0) {
          this.endShark()
          let info = res.data.data
          let danwei = ''
          let typeName = ''
          switch (info.type) {
            case '1': danwei = '元', typeName = '红包'; break
            case '2': danwei = '碎片', typeName = '碎片'; break
            case '3': danwei = '金叶子', typeName = '金叶子'; break
            case '4': danwei = '驿马', typeName = '驿马'; break
            case '5': danwei = '官凭', typeName = '官凭'; break
          }
          let yao_res = { type: info.type, danwei, amount: info.amount, typeName, name:  info.name}
          this.yao_res = yao_res
          this.endInfo = info.type
          setTimeout(() => {
            this.loading = false
            if (info.type == '0') {
              this.NoneJiang = true
            } else {
              this.show = true
            }
            this.getPage2()
          }, 500)
        } else {
          Toast(res.data.message)
          this.startSharking()
          this.loading = false
        }
      }).catch(() => {
        this.startSharking()
        this.loading = false
      })
    },

    getsuipianPage2 () {
      this.loading = true
      getsuipianPage(this.formInline).then(res => {
        if (res.data.code === 0) {
          this.endShark()
          let info = res.data.data
          let danwei = ''
          let typeName = ''
          switch (info.type) {
            case '1': danwei = '元', typeName = '红包'; break
            case '2': danwei = '碎片', typeName = '碎片'; break
            case '3': danwei = '金叶子', typeName = '金叶子'; break
            case '4': danwei = '驿马', typeName = '驿马'; break
            case '5': danwei = '官凭', typeName = '官凭'; break
          }
          let yao_res = { type: info.type, danwei, amount: info.amount, typeName, name:  info.name}
          this.yao_res = yao_res
          this.endInfo = info.type
          setTimeout(() => {
            this.loading = false
            if (info.type == '0') {
              this.NoneJiang = true
            } else {
              this.show = true
            }
            this.getPage2()
          }, 500)
        } else {
          Toast(res.data.message)
          this.startSharking()
          this.loading = false
        }
      }).catch(() => {
        this.startSharking()
        this.loading = false
      })
    },

    getYaoyiyaoPage () {
      getYaoyiyaoPage().then(res => {
        if (res.data.code === 0) {
          this.totalActivity = Number(res.data.data.totalActivity)
          const shakeActivity = res.data.data.shakeActivity
          if (shakeActivity) {
            this.noneActive = false
            this.exchange = shakeActivity.exchange
            this.formInline.activityId = shakeActivity.id
            this.activityRule = res.data.data.shakeActivity.activityRule ? res.data.data.shakeActivity.activityRule.split('\n'):[]
            this.activation = Number(shakeActivity.activation)
            const info = res.data.data.shakeActivity
            let zhifuType = []
            shakeActivity.ricePay == '1' ? zhifuType.push({ type: '2', name: '驿马', jine: Number(info.postHorse) }) : ''
            shakeActivity.goldPay == '1' ? zhifuType.push({ type: '3', name: '金叶子', jine: Number(info.goldRice) }) : ''
            shakeActivity.rewardPay == '1' ? zhifuType.push({ type: '4', name: '官凭', jine: Number(info.rewardPoints) }) : ''
            this.zhifuType = zhifuType
          } else {
            this.noneActive = true
          }
        }
      }).catch(() => {
      })
    },

    getTiskList () {
      getTiskList().then(res => {
        if (res.data.code === 0) {
          let tiskList = [
            { name: '邀请好友助力', activeType: '0', list: [] },
            { name: '看广告', activeType: '1', list: [] },
            { name: '逛逛武林', activeType: '2', list: [] },
            { name: '浏览商城', activeType: '3', list: [] }
          ]
          res.data.data.forEach(x => {
            if (x.activeType == 3) {
              this.active = x.active
            }
            let activeTypeName = ''
            let activeTyDanwei = ''
            switch (x.activeType) {
              case 1: activeTypeName = '邀请1个新用户助力可', activeTyDanwei = '活跃度/位', tiskList[0].list.push(x); break
              case 2: activeTypeName = '邀请1个老用户助力可', activeTyDanwei = '活跃度/位', tiskList[0].list.push(x); break
              case 3: activeTypeName = '看广告可获得', activeTyDanwei = '活跃度/个', tiskList[1].list.push(x); break
              case 4: activeTypeName = '观看1个短视频', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 5: activeTypeName = '给短视频点赞', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 6: activeTypeName = '举报视频平台审核通过', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 8: activeTypeName = '举报评论平台审核通过', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 9: activeTypeName = '给短视频评论', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 7: activeTypeName = '每浏览商城', activeTyDanwei = '活跃度/分钟', tiskList[3].list.push(x); break
            }
            x.activeTypeName = activeTypeName
            x.activeTyDanwei = activeTyDanwei
          })
          this.tiskList = tiskList
        }
      })
    },

    goGuang (activeType) {
      if (activeType == '0') {
        this.$router.push({
          path: '/invitationRegister',
          query: {
            session: this.$route.query.session
          }
        })
      } else if (activeType == '1') {
        this.showRewardVideo()
      } else if (activeType == '2') {
        this.activityTaskVideo()
      } else if (activeType == '3') {
        this.activityTaskMall()
      }
    },

    getErCode () {
      let params = {
        content: "http://mall.hfzlwl.com/app/#/loginShare",
        width: 200,
        height: 200,
        logoUrl: '',
        logoWidth: 50,
        logoHeight: 50,
        types: '8'
      }
      getErCode(params).then(res => {
        if (res.data.code === 0) {
          this.src = res.data.data.downloadUrl
        }
      }).catch(() =>{
      })
    },

    getSuipianliList () {
      this.list = [
        '137****1545摇中了现金红包',
        '153****8544摇中了碎片奖励',
        '181****5512摇中了现金红包',
        '189****8956摇中了现金红包',
        '181****9125摇中了现金红包',
        '195****1022摇中了碎片奖励',
        '183****8254摇中了碎片奖励',
        '186****5236摇中了碎片奖励',
        '134****8226摇中了现金红包',
        '136****9864摇中了碎片奖励',
        '137****5565摇中了现金红包',
        '189****5523摇中了碎片奖励',
        '191****2321摇中了现金红包',
        '135****1231摇中了碎片奖励',
        '136****2721摇中了现金红包',
        '137****2511摇中了碎片奖励',
        '183****1589摇中了碎片奖励',
        '183****5656摇中了现金红包',
        '188****2895摇中了现金红包',
        '133****7878摇中了碎片奖励',
        '137****1615摇中了现金红包',
        '153****8954摇中了碎片奖励',
        '181****5612摇中了现金红包',
        '189****2255摇中了碎片奖励',
        '131****9895摇中了现金红包',
        '137****1615摇中了碎片奖励',
        '153****8954摇中了碎片奖励',
        '136****5286摇中了现金红包',
        '134****8956摇中了现金红包',
        '136****9564摇中了碎片奖励',
        '137****4511摇中了现金红包',
        '189****5653摇中了碎片奖励',
        '191****5612摇中了现金红包',
        '135****2265摇中了碎片奖励',
        '136****9565摇中了现金红包',
        '137****1615摇中了碎片奖励',
        '183****8964摇中了碎片奖励',
        '133****5656摇中了现金红包',
        '137****2855摇中了现金红包',
        '133****5648摇中了碎片奖励'
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  background: transparent;
  .van-nav-bar__left,
  .van-nav-bar__right {
    padding: 0 15px;
  }
  .van-nav-bar__title,
  .van-icon-arrow-left {
    color: #000;
  }
}
@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-200%);
  }
}
.lunbo {
  width: 100%;
  position: absolute;
  right: 0;
  top: 110px;
  overflow: hidden;
  z-index: 999;
}
.jiangRecordsList {
  width: 100%;
  display: flex;
  font-size: 12px;
  color: #E54320;
  display: flex;
  align-items: center;
  animation: scroll 20s linear infinite;
  &-item {
    background: rgba(233, 252, 253, .5);
    margin-right: 15px;
    flex-shrink: 0;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 2px 10px;
  }
}
.jiangRecords {
  display: flex;
  position: fixed;
  right: 0;
  top: 145px;
  z-index: 10;
  &-item {
    padding: 5px;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    border-right: 1px solid transparent;
    background: rgba(0, 0, 0, .4);
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
  }
}
.container {
  min-height: 100%;
  background: #ffebdc;
  background-size: 100% auto;
  box-sizing: border-box;
  position: relative;
   &-yun {
    width: 100%;
    position: absolute;
    left: 0;
    top: 55px;
  }
}
.beijing {
  position: relative;
  &-img {
    width: 100%;
  }
  &-dazhuanpan {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    &-shou {
      width: 40%;
    }
    &-yao {
      width: 80%;
    }
      &-yun {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      &-item {
        width: 45%;
        position: relative;
        &-content {
          color: #FF5400;
          font-size: 21px;
          position: absolute;
          left: 50%;
          top: 5px;
          transform: translateX(-50%);
        }
        &-img {
          width: 100%;
        }
      }
    }
  }
}
.activeRule {
  width: 95%;
  background: linear-gradient(#ffebdc, #fff 50px);
  border-radius: 15px;
  margin: 15px auto;
  padding: 0 15px 15px;
  box-sizing: border-box;
  &-text {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    &-zi {
      color: #FF5400;
      background: #FFD5A5;
      padding: 10px 40px;
      border-radius: 0 0 15px 15px;
    }
  }
  &-ul {
    font-size: 14px;
    &-li {
      margin-bottom: 5px;
    }
  }
}
.userListBox {
  padding-bottom: 10px;
}
.userList {
  width: 95%;
  margin: auto;
  background: #fff;
  border-radius: 15px;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;

  &-btn {
    display: flex;
    justify-content: space-between;
    &-name {
      color: #671A0E;
      font-size: 16px;
      font-weight: bold;
    }
  }
  &-item {
    color: #333;
    font-size: 14px;
    display: flex;
    align-items: center;
    &-yuan {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #FFB68C;
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }
}
</style>