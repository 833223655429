<template>
  <div>
    <transition name="ani">
      <div class="zhezhaoceng" v-if="_show">
        <p class="zhezhaoceng-title">手气不错哦，摇中了{{ yao_res.typeName }}~</p>

        <div class="container">
          <img class="container-img" src="../img/jiang.png" alt="">

          <div style="height: 70px;"></div>

          <div class="container-jine container-item" v-if="yao_res.type != '2'">
            <span class="container-jine-num">{{ yao_res.amount }}</span>
            <span class="container-jine-yuan">{{ yao_res.danwei }}</span>
          </div>

          <p class="container-title container-item" v-if="yao_res.type == '2'">碎片：{{ yao_res.name }}</p>
          <p class="container-title container-item" v-else>奖品已放在你的账户中~</p>

          <div class="container-qvkankan container-item">
            <span class="container-qvkankan-text">看广告获得{{ active }}活跃度</span>
            <span>
              <van-button 
                round 
                size="mini" 
                color="linear-gradient(to right, #FF4982, #FF3F3F)"
                @click="qvzhuanqv">
                <div style="margin: 0 3px;">去赚取</div>
              </van-button>
            </span>
          </div>

          <div class="container-btn">
            <span class="container-btn-item" style="margin-right: 10px;">
              <van-button 
                round 
                color="linear-gradient(#fffefd, #ffe2b7)"
                @click="tryAgain">
                <span style="color: #FF5400;">再来一次</span>
              </van-button>
            </span>

            <span class="container-btn-item">
              <van-button 
                round 
                color="linear-gradient(#ffb85f, #ffe9c5)"
                @click="shareFriend">
                <span style="color: #FF5400;">分享好友</span>
              </van-button>
            </span>
          </div>
        </div>

        <div>
          <van-icon name="close" size="30" color="#fff" @click="tryAgain" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    yao_res: {
      type: Object
    },
    active: {
      type: Number
    }
  },

  computed: {
    _show: {
      get () {
        return this.show
      },
      set () {
        this.$emit('closeShow')
      }
    }
  },

  methods: {
    tryAgain () {
      this._show = false
      this.$emit('tryAgain')
    },

    qvzhuanqv () {
      this._show = false
      this.$emit('qvzhuanqv')
    },

    shareFriend () {
      this._show = false
      this.$emit('openShareFriend')
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &-title {
    font-size: 24px;
    color: #FFE2B4;
  }
  .container {
    width: 90%;
    margin: 100px auto 50px;
    padding: 10px 20px 25px;
    border: 1px solid #ffee96;
    border-radius: 20px;
    box-sizing: border-box;
    background: linear-gradient(#ff5822 50%, #fdd26b);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &-item {
      margin-bottom: 20px;
    }
    &-img {
      width: 200px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-title {
      color: #fff;
      font-size: 20px;
    }
    &-qvkankan {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      background: #FFE6C1;
      box-sizing: border-box;
      &-text {
        color: #832604;
      }
    }
    &-jine {
      color: #fff;
      &-num {
        font-size: 50px;
      }
      &-yuan {
        font-size: 12px;
      }
    }
    &-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &-item {
        flex: 1;
      }
      .van-button--round {
        width: 100%;
      }
    }
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>